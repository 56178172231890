import { useSnackbarHandlers } from './snackbarHandlers';
import { useAuth } from '../hooks/useAuth'; 
import ApiService from "../api/api";

const PYTHON_URL = process.env.REACT_APP_PYTHON_URL;
const apiService = new ApiService(PYTHON_URL);

export const useFormSubmitHandlers = ({ isRegister = false, formData, onSuccess }) => {
  const { handleSnackbar } = useSnackbarHandlers();
  const { login: authLogin } = useAuth();

  const { username, email, password, confirmPassword } = formData;

  // Extract auth functions from ApiService
  const { login, register } = apiService;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isRegister && password !== confirmPassword) {
      handleSnackbar("Passwords do not match", "error");
      return;
    }

    try {
      if (isRegister) {
        await register(username, password, email);
        handleSnackbar("Registration successful", "success");
        if (onSuccess) onSuccess();
      } else {
        const {user_id} = await login(username, password);
        await authLogin({ user_id });
        handleSnackbar("Login successful", "success");
      }
      
    } catch (error) {
      console.error(`Error ${isRegister ? "registering" : "logging in"}:`, error);
      handleSnackbar(error.message || `${isRegister ? "Registration" : "Login"} failed`, "error");
    }
  };

  return { 
    handleSubmit,
  };
};