export default class ApiService {
  constructor(baseURL) {
    this.baseURL = baseURL;

    // Bind standalone passed functions
    this.login = this.login.bind(this);
    this.register = this.register.bind(this);
  }

  // Utility function to handle API requests
  async apiRequest(endpoint, method = "GET", body = null) {
    const api_url = `${this.baseURL}${endpoint}`;
    const options = {
      method,
      headers: { "Content-Type": "application/json" },
    };
    if (body) options.body = JSON.stringify(body);

    try {
      const response = await fetch(api_url, options);
      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message);
      }
      return await response.json();
    } catch (error) {
      console.error(`Error during ${method} ${endpoint}:`, error);
      throw error;
    }
  }

  // Fetch all from the server
  async fetchAll() {
    return this.apiRequest("/all");
  }

  // Fetch single entity by ID
  async fetchEntityById(entityId) {
    return this.apiRequest(`/${entityId}`);
  }

  // Add a new entity to the server
  async add(newEntity) {
    return this.apiRequest("/create", "POST", newEntity);
  }

  // Edit an existing entity on the server
  async edit(entityId, updatedEntity) {
    return this.apiRequest(`/${entityId}`, "PUT", updatedEntity);
  }

  // Delete an entity from the server
  async deleteEntity(entityId) {
    return this.apiRequest(`/${entityId}`, "DELETE");
  }

  // Bulk delete multiple Entities from the server
  async deleteSelectedEntities(selectedRows) {
    return this.apiRequest("/delete-multiple", "POST", { ids: selectedRows });
  }

  // Fetch stats from the server
  async getStatistics() {
    return this.apiRequest("/statistics");
  }

  // Authentication APIs
  async login(username, password) {
    return this.apiRequest("/auth/login", "POST", { username, password });
  }

  async register(username, password, email) {
    return this.apiRequest("/auth/register", "POST", {
      username,
      password,
      email,
    });
  }
}
