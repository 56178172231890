import React, { useState } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
import CreateForm from "./CreateForm";
import usePrivilegesCheck from "../hooks/usePrivilegesCheck";

const NavigationBar = ({
  showTable,
  showTableView,
  showCompleteDetailsView,
  onAdminClick,
  logout,
  onCreateClick,
  onUpdateClick,
  onDeleteClick,
}) => {
  const [showCreateForm, setShowCreateForm] = useState(false);

  // Check if user has admin privileges (all actions)
  const { hasPrivileges: isAdmin } = usePrivilegesCheck(["all"]); // Check for "all" privilege for admin access

  // Check if the user can create (not just admin)
  const { hasPrivileges: canAdd } = usePrivilegesCheck(["add"]);

  // Check if the user can edit (not just admin)
  const { hasPrivileges: canEdit } = usePrivilegesCheck(["edit"]);

  const handleCreateClick = () => {
    setShowCreateForm(true);
  };

  return (
    <header>
      <Navbar
        bg="light"
        expand="md"
        fixed="top"
        className="container-nav navbar-light bg-light"
      >
        <Navbar.Brand href="/" className="color-maroon">
          KI-Kompass Inklusiv
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Item>
              <Button
                onClick={showTableView}
                className={`nav-link btn ${
                  showTable
                    ? "btn-primary active nav-heading-name"
                    : "btn-outline-secondary nav-heading-name-main"
                }`}
              >
                Technologieübersicht
              </Button>
            </Nav.Item>
            <Nav.Item>
              <Button
                onClick={showCompleteDetailsView}
                className={`nav-link btn ${
                  !showTable
                    ? "btn-primary active nav-heading-name"
                    : "btn-outline-secondary nav-heading-name-main"
                }`}
                style={{ marginLeft: "10px" }}
              >
                Tabellenansicht komplett
              </Button>
            </Nav.Item>
          </Nav>
          <Nav className="ml-auto">
            {canAdd && ( // Only show Create button if user can add
              <Nav.Item>
                <Button
                  onClick={onCreateClick}
                  className="nav-link btn btn-info"
                  style={{ marginLeft: "10px" }}
                >
                  Create
                </Button>
              </Nav.Item>
            )}

            {canEdit && ( // Only show Update button if user can edit
              <Nav.Item>
                <Button
                  onClick={onUpdateClick}
                  className="nav-link btn btn-info"
                  style={{ marginLeft: "10px" }}
                >
                  Update
                </Button>
              </Nav.Item>
            )}

            {isAdmin && ( // Only show Admin button if isAdmin is true
              <Nav.Item>
                <Button
                  onClick={onAdminClick}
                  className="nav-link btn btn-warning"
                  style={{ marginLeft: "10px" }}
                >
                  Admin
                </Button>
              </Nav.Item>
            )}

            <Nav.Item>
              <Button
                onClick={logout}
                className="nav-link btn btn-danger"
                style={{ marginLeft: "10px" }}
              >
                Logout
              </Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <br />
      <br />
      <br />
      {showCreateForm && <CreateForm />}
    </header>
  );
};

export default NavigationBar;
